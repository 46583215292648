import axios from "axios"
import config from "../../../configs/clientConfig";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import { history } from "../../../history"
import { toast } from "react-toastify"

export const LOGIN_WITH_JWT = "LOGIN_WITH_JWT";
export const LOGOUT = "LOGOUT";

const LOGIN_WITH_JWT_URL = "/adminlogin";
const REFRESH_JWT_TOKEN_URL = "/token/refresh";

export const loginWithJWT = (user) => {
  return dispatch => {
    axios
      .post(config.apiServerHost+LOGIN_WITH_JWT_URL, {
        username: user.username,
        password: user.password
      })
      .then(response => {
        let decodedToken = jwt.decode(response.data.accessToken, { complete: true });
        dispatch({
          type: LOGIN_WITH_JWT,
          user: decodedToken.payload.mobile
        });

        Cookies.set("nt_garments_admin_token", response.data.accessToken, {sameSite: 'None', secure: true});
        Cookies.set("nt_garments_admin_code", response.data.refreshToken, {sameSite: 'None', secure: true});
        history.push("/");
      })
      .catch(error => {
        toast.error("Username/Password Invalid");
      })
  }
}

export const logoutUser = () => {
  return dispatch => {
    dispatch({
      type: LOGOUT
    });
    internalLogOut();
  }
}

export const syncUserDetailsFromToken = (token) => {
  return dispatch => {
    let decodedToken = jwt.decode(token, { complete: true });
    dispatch({
      type: LOGIN_WITH_JWT,
      user: decodedToken.payload.mobile
    });
  }
}

export const refreshJwtToken = (error) => {
  let accessToken = Cookies.get("nt_garments_admin_token");
  let refreshToken = Cookies.get("nt_garments_admin_code");

  if (accessToken && refreshToken) {
    let decodedToken = jwt.decode(accessToken, { complete: true });

    return axios.post(config.apiServerHost+REFRESH_JWT_TOKEN_URL+"?type=admin", {
      username: decodedToken.payload.mobile,
      token: refreshToken
    })
    .then(response => {
      Cookies.set("nt_garments_admin_token", response.data.accessToken, {sameSite: 'None', secure: true});
      error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
      return axios(error.response.config);
    })
    .catch(error => {
      console.log(error);
      if(error.response.status === 400) {
        internalLogOut();
      }
      return Promise.reject(error);
    });
  }
}

const internalLogOut = () => {
  Cookies.remove('nt_garments_admin_token', {sameSite: 'None', secure: true})
  Cookies.remove('nt_garments_admin_code', {sameSite: 'None', secure: true})
  history.push("/login")
}