import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap"
import * as Icon from "react-feather"
import { connect } from "react-redux"
import {
  loadSuggestions,
  updateStarred
} from "../../../redux/actions/navbar/Index"

class NavbarBookmarks extends React.PureComponent {
  render() {
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="nav navbar-nav bookmark-icons">
          <NavItem className="nav-item d-none d-lg-block">
            <NavLink to="/app-mail" id="appMail">
              <Icon.PlusCircle size={21} />
            </NavLink>
            <UncontrolledTooltip placement="bottom" target="appMail">
              Add Products
            </UncontrolledTooltip>
          </NavItem>
        </ul>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    bookmarks: state.navbar
  }
}

export default connect(mapStateToProps, { loadSuggestions, updateStarred })(
  NavbarBookmarks
)
