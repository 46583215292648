import {
  LOGIN_WITH_JWT,
  LOGOUT
} from "../../actions/login/index";

const initialState = {
  loggedIn: false,
  loggedInUser: '',
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_WITH_JWT: {
      return {
        ...state,
        loggedIn: true,
        loggedInUser: action.user
      };
    }

    case LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        loggedInUser: ''
      }
    }

    default:
      return state;
  }
};

export default login;
