import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/dashboard",
  },
  {
    type: "groupHeader",
    groupTitle: "USER"
  },
  {
    id: "user",
    title: "User",
    type: "collapse",
    icon: <Icon.User size={20} />,
    children: [
      {
        id: "manageUser",
        title: "Manage User",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/user/list"
      },
      {
        id: "manageAgent",
        title: "Manage Agent",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/agent/list"
      }
    ]
  },
  {
    type: "groupHeader",
    groupTitle: "PRODUCT"
  },
  {
    id: "inventory",
    title: "Inventory",
    type: "collapse",
    icon: <Icon.ShoppingCart size={20} />,
    children: [
      {
        id: "yourProducts",
        title: "Your Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/inventory/product"
      },
      {
        id: "addProducts",
        title: "Add Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/inventory/product/add"
      },
    ]
  },
  {
    id: "config",
    title: "Config",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    children: [
      {
        id: "banner",
        title: "Banner List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/inventory/manage/banner-list"
      },
      {
        id: "categoryList",
        title: "Category List",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/inventory/manage/category-list"
      },
      {
        id: "appConfig",
        title: "App Config",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/order/manage/app-config"
      },
      {
        id: "userNotification",
        title: "User Notification",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/order/manage/user-notification"
      },
    ]
  },
  {
    id: "orders",
    title: "Orders",
    type: "collapse",
    icon: <Icon.List size={20} />,
    children: [
      {
        id: "orderDetails",
        title: "Order Details",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/order/details"
      }
    ]
  },
  {
    id: "reports",
    title: "Reports",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    children: [
      {
        id: "salesReport",
        title: "Sales",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/report/sales"
      }
    ]
  }
]

export default navigationConfig
