import {
  UPDATE_SELECTED_CATEGORY,
  CLEAR_SELECTED_CATEGORY,

  ADD_SELECTED_CATEGORY_IMG,
  REMOVE_SELECTED_CATEGORY_IMG,

  GET_ALL_BANNERS,
  DELETE_ALL_BANNERS,
  ADD_BANNER_IMG,
  REMOVE_BANNER_IMG
} from "../../actions/manage/index";

const initialState = {
  selectedCategory: {
    categoryId: "",
    categoryName: "",
    superCategoryId: "",
    imageUrl: ""
  },
  selectedImage: undefined,
  bannerList: [],
  bannerUrls: [],
};

const todo = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_CATEGORY: {
      let { selectedCategory } = state;

      selectedCategory[action.key] = action.value;
      return {
        ...state,
        selectedCategory: selectedCategory,
      };
    }

    case CLEAR_SELECTED_CATEGORY: {
      return {
        ...state,
        selectedCategory: {
          categoryId: "",
          categoryName: "",
          superCategoryId: "",
          imageUrl: ""
        }
      }
    }

    case ADD_SELECTED_CATEGORY_IMG: {
      let { selectedImage } = state;
      selectedImage && URL.revokeObjectURL(selectedImage.preview)

      return {
        ...state,
        selectedImage: action.image
      }
    }

    case REMOVE_SELECTED_CATEGORY_IMG: {
      let { selectedImage } = state;
      selectedImage && URL.revokeObjectURL(selectedImage.preview)

      return {
        ...state,
        selectedImage: undefined
      }
    }

    case GET_ALL_BANNERS: {
      return {
        ...state,
        bannerList: action.data.bannerList
      }
    }

    case ADD_BANNER_IMG: {
      let { bannerUrls } = state;
      bannerUrls.push(action.url);
      return {
        ...state,
        bannerUrls: bannerUrls
      }
    }

    case REMOVE_BANNER_IMG: {
      let { bannerList } = state;
      
      bannerList = bannerList.filter(banner => banner.image_url != action.url);

      return {
        ...state,
        bannerList: bannerList
      }
    }

    case DELETE_ALL_BANNERS: {
      return {
        ...state,
        bannerUrls: []
      }
    }

    default:
      return state;
  }
};

export default todo;
