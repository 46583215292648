import axios from "axios"

import config from "../../../configs/clientConfig";
import { history } from "../../../history"
import { toast } from "react-toastify"

const GET_INIT_DATA_URL = "/initdata";
const GET_ALL_PRODUCTS_URL = "/getallproducts";
const GET_PRODUCT_BY_ID_URL = "/getproductbyid";
const ADD_PRODUCT_URL = "/addproduct";
const UPDATE_PRODUCT_URL = "/updateproduct";
const ADD_PRODUCT_SET_URL = "/addproductset";
const UPDATE_PRODUCT_SET_URL = "/updateproductset";
const UPLOAD_PRODUCT_IMG_URL = "/upload";

export const GET_INIT_DATA = "GET_INIT_DATA";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_PRODUCT_BY_ID = "GET_PRODUCT_BY_ID";

export const ADD_SELECTED_IMG = "ADD_SELECTED_IMG";
export const REMOVE_SELECTED_IMG = "REMOVE_SELECTED_IMG";

export const CLEAR_SELECTED_PRODUCT = "CLEAR_SELECTED_PRODUCT";
export const UPDATE_SELECTED_PRODUCT = "UPDATE_SELECTED_PRODUCT";
export const ADD_SELECTED_PRODUCT_IMG = "ADD_SELECTED_PRODUCT_IMG";
export const UPDATE_SELECTED_PRODUCT_IMG = "UPDATE_SELECTED_PRODUCT_IMG";
export const DELETE_SELECTED_PRODUCT_IMG = "DELETE_SELECTED_PRODUCT_IMG";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const APPLY_PRODUCT_FILTER = "APPLY_PRODUCT_FILTER";

export const CLEAR_SELECTED_PRODUCT_SET = "CLEAR_SELECTED_PRODUCT_SET";
export const UPDATE_SELECTED_PRODUCT_SET = "UPDATE_SELECTED_PRODUCT_SET";
export const ADD_SELECTED_PRODUCT_SET_IMG = "ADD_SELECTED_PRODUCT_SET_IMG";
export const UPDATE_SELECTED_PRODUCT_SET_IMG = "UPDATE_SELECTED_PRODUCT_SET_IMG";
export const DELETE_SELECTED_PRODUCT_SET_IMG = "DELETE_SELECTED_PRODUCT_SET_IMG";
export const ADD_PRODUCT_SET = "ADD_PRODUCT_SET";

export const PRODUCT_IS_LOADING = "PRODUCT_IS_LOADING";
export const PRODUCT_LOADED = "PRODUCT_LOADED";

export const productLoading = () => {
  return dispatch => {
    dispatch({
      type: PRODUCT_IS_LOADING
    });
  }
}

export const productLoaded = () => {
  return dispatch => {
    dispatch({
      type: PRODUCT_LOADED
    });
  }
}

export const getInitData = () => {
  return dispatch => {
    axios
      .get(config.apiServerHost+GET_INIT_DATA_URL)
      .then(response => {
        dispatch({
          type: GET_INIT_DATA,
          init_data: response.data
        })
      })
  }
}

export const getAllProducts = () => {
  let user = {
    'userId': 'admin'
  }
  return dispatch => {
    dispatch(productLoading());

    axios
      .post(config.apiServerHost+GET_ALL_PRODUCTS_URL, user)
      .then(response => {
        dispatch({
          type: GET_ALL_PRODUCTS,
          data: response.data
        })
        dispatch(productLoaded());
      })
  }
}

export const getProductById = (prod_id) => {
  let user = {
    'userId': 'admin'
  }
  return dispatch => {
    axios
      .post(config.apiServerHost+GET_PRODUCT_BY_ID_URL+"/"+prod_id, user)
      .then(response => {
        dispatch({
          type: GET_PRODUCT_BY_ID,
          data: response.data
        })
      })
  }
}

export const addSelectedImages = (images) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_IMG,
      images: images
    })
  }
}

export const removeSelectedImages = (imageId) => {
  return dispatch => {
    dispatch({
      type: REMOVE_SELECTED_IMG,
      imageId: imageId
    })
  }
}

export const clearSelectedProduct = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT,
    })
    dispatch(removeSelectedImages(-1))
  }
}

export const updateSelectedProduct = (key,value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT,
      key: key,
      value: value
    })
  }
}

export const addSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}

export const updateSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}

export const deleteSelectedProductImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: DELETE_SELECTED_PRODUCT_IMG,
      imgUrl: imgUrl
    })
  }
}


export const addProduct = () => {
  return (dispatch, getState) => {
    dispatch(productLoading());
    axios
      .post(config.apiServerHost+ADD_PRODUCT_URL, getState().productApp.product.selectedProduct)
      .then(response => {
        let { prodId } = response.data;
        dispatch(clearSelectedProduct());
        dispatch(productLoaded());
        toast.success("Product Created")
        history.push("/inventory/product/view/"+prodId);
      }).catch(error => {
        dispatch(productLoaded());
        toast.error("Failed to add product");
      })
  }
}

export const updateProduct = (prodId) => {
  return (dispatch, getState) => {
    dispatch(productLoading());
    axios
      .put(config.apiServerHost+UPDATE_PRODUCT_URL+"/"+prodId, getState().productApp.product.selectedProduct)
      .then(response => {
        dispatch(clearSelectedProduct());
        dispatch(productLoaded());
        toast.success("Product Updated")
        history.push("/inventory/product/view/"+prodId);
      }).catch(error => {
        dispatch(productLoaded());
        toast.error("Failed to update product");
      })
  }
}

export const filterOnProducts = (filterKey, filterValue) => {
  return dispatch => {
    dispatch({
      type: APPLY_PRODUCT_FILTER,
      filterKey: filterKey,
      filterValue: filterValue
    });
  }
}

export function uploadImage(imgFile, callback, isSmallImage) {
  let imageSuffix = "_lg";
  if(isSmallImage) {
    imageSuffix = "_sm"
  }

	return dispatch => {
		let formData = new FormData();
		formData.append('avatar', imgFile);
		return axios.post(
			config.imgServerHost+UPLOAD_PRODUCT_IMG_URL,
			formData,
			{
			  headers: {
			    'content-type': 'multipart/form-data'
			  }
			}
		).then(response => {
				let imgArray = response.data.images;
				for(let i=0; i<imgArray.length; i++) {
					if(imgArray[i].includes(imageSuffix)) {
						dispatch(callback(imgArray[i]));
						break;
					}
				}
      }
    );
	}
}

export const clearSelectedProductSet = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_SELECTED_PRODUCT_SET,
    })
    dispatch(removeSelectedImages(-1))
  }
}

export const updateSelectedProductSet = (key,value) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_SET,
      key: key,
      value: value
    })
  }
}

export const addSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: ADD_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const updateSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: UPDATE_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const deleteSelectedProductSetImg = (imgUrl) => {
  return dispatch => {
    dispatch({
      type: DELETE_SELECTED_PRODUCT_SET_IMG,
      imgUrl: imgUrl
    })
  }
}

export const addProductSet = () => {
  return (dispatch, getState) => {
    dispatch(productLoading());

    let { prodId } = getState().productApp.product.selectedProductSet;
    axios
      .post(config.apiServerHost+ADD_PRODUCT_SET_URL, getState().productApp.product.selectedProductSet)
      .then(response => {
        dispatch(clearSelectedProductSet());
        dispatch(getProductById(prodId))
        dispatch(productLoaded())
        toast.success("Product Set Created")
      })
      .catch(error => {
        dispatch(productLoaded())
        toast.error("Product Set Creation Failed")
      }) 
  }
}

export const updateProductSet = (prodSetId) => {
  return (dispatch, getState) => {
    dispatch(productLoading());

    let { prodId } = getState().productApp.product.selectedProductSet;
    axios
      .put(config.apiServerHost+UPDATE_PRODUCT_SET_URL+"/"+prodSetId, getState().productApp.product.selectedProductSet)
      .then(response => {
        dispatch(clearSelectedProductSet());
        dispatch(getProductById(prodId));
        dispatch(productLoaded())
        toast.success("Product Set Updated")
      })
      .catch(error => {
        dispatch(productLoaded())
        toast.error("Product Set Update Failed")
      }) 
  }
}