import {
  GET_INIT_DATA,
  GET_ALL_PRODUCTS,
  GET_PRODUCT_BY_ID,

  ADD_SELECTED_IMG,
  REMOVE_SELECTED_IMG,

  ADD_SELECTED_PRODUCT_IMG,
  UPDATE_SELECTED_PRODUCT_IMG,
  DELETE_SELECTED_PRODUCT_IMG,
  UPDATE_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCT,
  APPLY_PRODUCT_FILTER,

  CLEAR_SELECTED_PRODUCT_SET,
  UPDATE_SELECTED_PRODUCT_SET,
  ADD_SELECTED_PRODUCT_SET_IMG,
  UPDATE_SELECTED_PRODUCT_SET_IMG,
  DELETE_SELECTED_PRODUCT_SET_IMG,

  PRODUCT_IS_LOADING, 
  PRODUCT_LOADED
} from "../../actions/product/index";

const initialState = {
  initDataLoaded: false,
  productLoading: false,
  productsList: [],
  categories: {
    categoryList: [],
    superCategoryList: [],
  },
  tagSpecifications: {
    tagTypes: [],
    tagLists: [],
  },
  cities: [],
  productView: {},
  selectedProduct: {
    title: "",
    description: "",
    moq: -1,
    tagList: {},
    tagIdList: "",
    deleteTagIdList: "",
    superCategoryId: "",
    categoryId: "",
    subCategoryId: "",
    imageList: [],
    addImageList: [],
    deleteImageList: [],
    isVisible: 1,
    hiddenCities: "",
    originCountry: "India"
  },
  selectedImages: [],
  selectedProductSet: {
    prodId: -1,
    description: "",
    aliasName: "",
    price: -1,
    mrp: -1,
    piecePerSet: -1,
    gst: -1,
    skuId: "",
    loadedSkuId: "",
    hsnCode: "",
    isSetVisible: 1,
    imageList: [],
    addImageList: [],
    deleteImageList: []
  },
  filters: {
    superCategory: 0,
    category: "",
    productSearch: "",
  },
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case GET_INIT_DATA: {
      return {
        ...state,
        categories: action.init_data.categories,
        tagSpecifications: action.init_data.tagSpecifications,
        cities: action.init_data.cities,
        initDataLoaded: true
      };
    }

    case GET_ALL_PRODUCTS: {
      return {
        ...state,
        productsList: action.data.productsList,
      };
    }

    case GET_PRODUCT_BY_ID: {
      return {
        ...state,
        productView: action.data.productList,
      };
    }

    case ADD_SELECTED_IMG: {
      let { images } = action;
      let { selectedImages } = state;
      let currentImageId = selectedImages.length;

      for(let index in images) {
        let image = images[index];
        image.id = currentImageId++;

        selectedImages.push(image);
      }

      return {
        ...state,
        selectedImages: selectedImages,
      };
    }

    case REMOVE_SELECTED_IMG: {
      let { selectedImages } = state;

      if (action.imageId === -1) {
        selectedImages.forEach(file => URL.revokeObjectURL(file.preview));
        selectedImages = [];
      } else {
        for(let index in selectedImages) {
            let img = selectedImages[index];
            if (img.id === action.imageId) {
              URL.revokeObjectURL(img.preview);
              selectedImages.splice(index, 1);
              break;
            }
        }
      }

      return {
        ...state,
        selectedImages: selectedImages,
      };
    }

    case CLEAR_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: {
          title: "",
          description: "",
          moq: -1,
          tagList: {},
          tagIdList: "",
          deleteTagIdList: "",
          superCategoryId: "",
          categoryId: "",
          subCategoryId: "",
          imageList: [],
          addImageList: [],
          deleteImageList: [],
          isVisible: 1,
          hiddenCities: "",
          originCountry: "India"
        },
        productView: {},
      };
    }

    case UPDATE_SELECTED_PRODUCT: {
      let { selectedProduct } = state;
      selectedProduct[action.key] = action.value;

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case ADD_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      selectedProduct.imageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case UPDATE_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      selectedProduct.addImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case DELETE_SELECTED_PRODUCT_IMG: {
      let { selectedProduct } = state;
      for(let index in selectedProduct.imageList) {
        let img = selectedProduct.imageList[index];
        if(img === action.imgUrl) {
          selectedProduct.imageList.splice(index,1);
        }
      }
      selectedProduct.deleteImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProduct: selectedProduct,
      };
    }

    case APPLY_PRODUCT_FILTER: {
      let { filters } = state;
      filters[action.filterKey] = action.filterValue;
      return {
        ...state,
        filters: filters,
      };
    }

    case CLEAR_SELECTED_PRODUCT_SET: {
      return {
        ...state,
        selectedProductSet: {
          prodId: -1,
          description: "",
          price: -1,
          mrp: -1,
          piecePerSet: -1,
          gst: -1,
          skuId: "",
          loadedSkuId: "",
          hsnCode: "",
          isSetVisible: 1,
          imageList: [],
          addImageList: [],
          deleteImageList: []
        }
      };
    }

    case UPDATE_SELECTED_PRODUCT_SET: {
      let { selectedProductSet } = state;
      selectedProductSet[action.key] = action.value;

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case ADD_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;
      selectedProductSet.imageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case UPDATE_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;
      selectedProductSet.addImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case DELETE_SELECTED_PRODUCT_SET_IMG: {
      let { selectedProductSet } = state;

      for(let index in selectedProductSet.imageList) {
        let img = selectedProductSet.imageList[index];
        if(img === action.imgUrl) {
          selectedProductSet.imageList.splice(index,1);
        }
      }
      selectedProductSet.deleteImageList.push(action.imgUrl);

      return {
        ...state,
        selectedProductSet: selectedProductSet,
      };
    }

    case PRODUCT_IS_LOADING: {
      return {
        ...state,
        productLoading: true
      }
    }

    case PRODUCT_LOADED: {
      return {
        ...state,
        productLoading: false
      }
    }

    default:
      return state;
  }
};

export default product;
