import axios from "axios"
import config from "../../../configs/clientConfig";
import { toast } from "react-toastify"

const GET_SHIPROCKET_ORDER_STATUS_LIST_URL = "/shiprocket/order-status/list";
const GET_ORDERS_DETAILS_URL = "/getorderdetails";
const GET_SELECTED_ORDERS_FOR_CSV_URL = "/getorderdetailscsv";
const GET_PAID_ORDER_DETAILS_URL = "/getpaidorderdetails";
const PROCESS_ORDER_URL = "/processorder";
const REQUEST_PICKUP = "/shiprocket/generate/pickup";
const DOWNLOAD_MANIFEST = "/shiprocket/download/manifest";
const DOWNLOAD_LABEL = "/shiprocket/download/label";
const DOWNLOAD_INVOICE = "/shiprocket/download/invoice";

export const GET_SHIPROCKET_ORDER_STATUS_LIST = "GET_SHIPROCKET_ORDER_STATUS_LIST";
export const GET_ORDERS_DETAILS = "GET_ORDERS_DETAILS";
export const TOGGLE_ORDER_ID_FOR_CSV = "TOGGLE_ORDER_ID_FOR_CSV";
export const GET_SELECTED_ORDERS_FOR_CSV = "GET_SELECTED_ORDERS_FOR_CSV";
export const SET_CSV_DATA = "SET_CSV_DATA";
export const CLEAR_ORDER_LIST = "CLEAR_ORDER_LIST";

export const getShiprocketOrderStatusList = () => {
  return dispatch => {
    axios
      .get(config.apiServerHost+GET_SHIPROCKET_ORDER_STATUS_LIST_URL)
      .then(response => {
        dispatch({
          type: GET_SHIPROCKET_ORDER_STATUS_LIST,
          data: response.data
        })
      })
  }
}

export const getOrders = (criteria) => {
  return dispatch => {
    dispatch({
      type: CLEAR_ORDER_LIST,
    })
    axios
      .post(config.apiServerHost+GET_ORDERS_DETAILS_URL, criteria)
      .then(response => {
        dispatch({
          type: GET_ORDERS_DETAILS,
          data: response.data
        })
      })
  }
}

export const getPaidOrders = (criteria) => {
  return dispatch => {
    dispatch({
      type: CLEAR_ORDER_LIST,
    })
    axios
      .post(config.apiServerHost+GET_PAID_ORDER_DETAILS_URL, criteria)
      .then(response => {
        dispatch({
          type: GET_ORDERS_DETAILS,
          data: response.data
        })
      })
  }
}

export const getSelectedOrdersForCsv = (csvDownloadCallback) => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length === 0) {
      toast.error("Select order(s) ID and try again");
      return;
    }
    axios
      .post(config.apiServerHost+GET_SELECTED_ORDERS_FOR_CSV_URL, {
        orderIdArr: getState().orderApp.order.orderIdForCsv
      })
      .then(response => {
        let data = response.data.orderList;
        let rowData = [ getState().orderApp.order.csvHeaders ];

        let itemCountMap = {}
        let prevOrderNumber = ''
        let prevOrderCount = 0

        data.forEach(row => {
          if(row.payment_mode != 'COD') {
            return;
          }

          console.log(prevOrderNumber, row.order_number);

          if(prevOrderNumber != '' && prevOrderNumber != row.order_number) {
            itemCountMap[prevOrderNumber] = prevOrderCount;
            prevOrderCount = 1;
          } else {
            prevOrderCount++;
          }

          prevOrderNumber = row.order_number
        })

        itemCountMap[prevOrderNumber] = prevOrderCount;

        data.forEach(row => {
          let date = new Date(row.order_created_on);
          let created_date = date.getDate()+"-"
                                +(date.getMonth()+1)
                                +"-"+date.getFullYear()
                                +" "+(date.getHours() < 10 ? "0"+date.getHours() : date.getHours())
                                +":"+(date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes())
          let first_name = "", last_name = "";
          if(row.customer_name && row.customer_name.includes(" ")) {
            let tokens = row.customer_name.split(" ");
            last_name = tokens[tokens.length-1];
            tokens = tokens.filter(e => e !== last_name)
            first_name = tokens.join(" ")
          } else {
            first_name = row.customer_name;
            last_name = row.customer_name;
          }

          console.log(row);
           
          rowData.push([
            row.order_number, created_date, "CUSTOM", row.payment_mode, first_name, last_name, row.email, row.mobile, "", row.shop_name+', '+row.shop_no+', '+row.shop_details, row.area,
            row.country, row.state, row.city, row.pincode, "", "", "", "", "", "", row.sku_id, row.title, (itemCountMap[row.order_number] ? 1 : row.set_quantity), "", (itemCountMap[row.order_number] ? (row.cod_amount/itemCountMap[row.order_number]).toFixed(2) : row.set_price), "", "", "", "", "", "", "", "",
            "", "", "", row.hsn_code, "", "", "", "", "", ""
          ]);
        });
        dispatch({
          type: SET_CSV_DATA,
          data: rowData
        });

        csvDownloadCallback();
      })
  }
}

export const toggleOrderIdForCsv = (order_id, selection) => {
  return dispatch => {
      dispatch({
        type: TOGGLE_ORDER_ID_FOR_CSV,
        order_id,
        selection
      })
  }
}

export const uploadOrderCsv = (file) => {
  return dispatch => {
    dispatch({
      type: CLEAR_ORDER_LIST,
    })

		let formData = new FormData();
		formData.append('file', file);
		return axios.post(
			config.apiServerHost+PROCESS_ORDER_URL,
			formData,
			{
			  headers: {
			    'content-type': 'multipart/form-data'
			  }
			}
		).then(response => {
      toast.success("Upload Initiated");
      dispatch(getOrders());
    }).catch(error => {
      toast.error("Upload failed, order details not added in Shiprocket");
      dispatch(getOrders());
    });
	}
}

export const viewInvoice = () => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }
    axios.get(config.apiServerHost+"/generate/invoice-token")
    .then(response => {
      let { invoice_token } = response.data;
      window.open(config.apiServerHost+"/viewinvoice/"+getState().orderApp.order.orderIdForCsv[0]+"?token="+invoice_token, "_blank")
    })
  }
}

export const downloadInvoice = () => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }
    axios.get(config.apiServerHost+"/generate/invoice-token")
    .then(response => {
      let { invoice_token } = response.data;
      window.open(config.apiServerHost+"/downloadinvoice/"+getState().orderApp.order.orderIdForCsv[0]+"?token="+invoice_token, "_blank")
    })
  }
}

export const requestPickup = (courierId) => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }

    let {
      orderIdForCsv
    } = getState().orderApp.order;

    axios.post(config.apiServerHost+REQUEST_PICKUP, {
      order_id: orderIdForCsv[0],
      courier_id: courierId
    }).then(response => {
      setTimeout(() => {
        toast.success("Pickup request success. AWB and Manifest generated");
        dispatch(getOrders());
      }, 2000);
    }).catch(error => {
      toast.error("Pickup request failed");
    });
  }
}

export const downloadManifest = () => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }

    let {
      orderIdForCsv, orderList
    } = getState().orderApp.order;

    for(let i=0; i<orderList.length; i++) {
      if(orderIdForCsv[0] == orderList[i].id) {
        axios.post(config.apiServerHost+DOWNLOAD_MANIFEST, {
            order_ids: [orderList[i].shiprocket_order_id]
          })
          .then(response => {
            window.open(response.data.data.manifest_url, "_blank")
          })
        break;
      }  
    }
  }
}

export const downloadLabel = () => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }
    
    let {
      orderIdForCsv, orderList
    } = getState().orderApp.order;

    for(let i=0; i<orderList.length; i++) {
      if(orderIdForCsv[0] == orderList[i].id) {
        axios.post(config.apiServerHost+DOWNLOAD_LABEL, {
            shipment_id: [orderList[i].shipment_id+""]
          })
          .then(response => {
            window.open(response.data.data.label_url, "_blank")
          })
        break;
      }  
    }
  }
}

export const downloadShiprocketInvoice = () => {
  return (dispatch, getState) => {
    if(getState().orderApp.order.orderIdForCsv.length !== 1) {
      toast.error("Select one OrderID and proceed");
      return;
    }
    
    let {
      orderIdForCsv, orderList
    } = getState().orderApp.order;

    for(let i=0; i<orderList.length; i++) {
      if(orderIdForCsv[0] == orderList[i].id) {
        axios.post(config.apiServerHost+DOWNLOAD_INVOICE, {
            ids: [orderList[i].shiprocket_order_id+""]
          })
          .then(response => {
            window.open(response.data.data.invoice_url, "_blank")
          })
        break;
      }  
    }
  }
}