const setup = 'production';
const version = '1.1.12';

const local = {
  apiServerHost: "http://localhost:4003",
  imgServerHost: "https://image.doubledevs.com",
  imgViewerUrl: "/uploads/avatars/responsive/",
  version
}

const develop = {
  apiServerHost: "https://devapi.nirmalatex.com",
  imgServerHost: "https://image.doubledevs.com",
  imgViewerUrl: "/uploads/avatars/responsive/",
  version
}

const release = {
  apiServerHost: "https://relapi.nirmalatex.com",
  imgServerHost: "https://image.doubledevs.com",
  imgViewerUrl: "/uploads/avatars/responsive/",
  version
}

const production = {
  apiServerHost: "https://api.nirmalatex.com",
  imgServerHost: "https://images.nirmalatex.com",
  imgViewerUrl: "/uploads/avatars/responsive/",
  version
}

const clientConfig = setup === 'local' ? local :
                      setup === 'develop' ? develop : 
                      setup === 'release' ? release : 
                      setup === 'production' ? production : {};

export default clientConfig;