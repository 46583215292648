import {
  GET_ALL_USERS,
} from "../../actions/user/index";

const initialState = {
  usersList: []
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        usersList: action.data.usersList,
      };
    }

    default:
      return state;
  }
};

export default user;
